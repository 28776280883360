import React, { useEffect, useState } from "react";
import { Col, Row, Table, message, Button, Form, Radio, Input, Modal, Select } from "antd";
import axios from 'axios';
import { FormOutlined } from '@ant-design/icons';
import Auxiliary from "../../../../util/Auxiliary";
import { API_URL } from '../../../../constants/APIConfig';
import 'moment/min/locales';

const { Option } = Select;
const RadioGroup = Radio.Group;

const ManageCustomer = () => {

  const [form] = Form.useForm();
  const token = sessionStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState({ open: false, user: null })
  const [usersList, setUsersList] = useState([])
  const [projects, setProjects] = useState([])
  const [isActive, setIsActive] = useState(1);

  function onChangeIsActive(e) {
    setIsActive(e.target.value);
  }

  const tasksListCols = [
    {
      title: 'Id', dataIndex: 'employeeId', width: 80,
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Name', dataIndex: 'name', width: 150,
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Email', dataIndex: 'email', width: 180,
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },
    {
      title: 'Project', dataIndex: 'projects', width: 150,
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },

    {
      title: 'Active', dataIndex: 'active', width: 50,
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },

    {
      title: 'Action', fixed: 'right', width: 50,
      dataIndex: 'id', render: userId => <div>
        <span><FormOutlined style={{ padding: '10px' }} onClick={() => { fetchSelectedUserDetails(userId) }} /></span>
      </div>
    }
  ]

  const onTaskFormFinish = values => {

    if (isModalOpen.user) {
      updateUserDetails(isModalOpen.user)
    } else {
      let newCustomer = {
        "employeeId": form.getFieldValue('empid'),
        "displayNameTx": form.getFieldValue('empname'),
        "emailTx": form.getFieldValue('email'),
        "projectId": form.getFieldValue('project'),
        "activateYnNm": form.getFieldValue('active'),
      }
      setLoading(true)
      axios.post(API_URL + '/api/users/newCustomerUser', newCustomer,
        {
          headers: {
            'x-access-token': `${token}`,
            'Access-Control-Allow-Origin': '*'
          }
        })
        .then(response => {
          message.success("Resource Created Successfully")
          setIsModalOpen({ open: false, user: null })
          form.resetFields()
          getCustomerProjects()
          setLoading(false)
        })
        .catch(error => {
          console.log(error);
          message.error("Error In creating Project", error)
          setLoading(false)
        });
    }
  }

  const getProjects = () => {
    axios.get(API_URL + '/api/project/v2/getAllP',
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        setProjects(response.data.data);
      })
      .catch(error => {
        console.log('Error', error);
      });
  }

  useEffect(() => {
    getProjects()
  }, [])

  const getCustomerProjects = () => {

    axios.get(API_URL + `/api/users/getcustomerProjects`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        let objRes = new Map()
        if (Object.keys(response.data.data).length > 0) {
          response.data.data.map((item, index) => {
            let userObj = objRes.get(item.userId)
            if (userObj) {
              userObj.projects.push(item.assignedProject.name)
            } else {
              let tempObj = {
                id: item.userId,
                employeeId: item.assignedUser.employeeId,
                name: item.assignedUser.displayNameTx,
                email: item.assignedUser.emailTx,
                projects: [item.assignedProject.name],
                active: item.assignedUser.activateYnNm,
                key: item.userId
              }
              objRes.set(item.userId, tempObj)
            }
          })
        }
        Array.from(objRes.values())
        setUsersList(Array.from(objRes.values()))
        form.setFieldsValue({ active: 1 })
      })
      .catch(error => {
        console.log(error);
      });
  }

  const updateUserDetails = (userId) => {
    let editCustomer = {
      "displayNameTx": form.getFieldValue('empname'),
      "emailTx": form.getFieldValue('email'),
      "activateYnNm": form.getFieldValue('active'),
    }
    setLoading(true)

    axios.put(API_URL + `/api/users/updateCustomerUser/${userId}`, editCustomer,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        message.success("Resource updated Successfully");
        setIsModalOpen({ open: false, user: null })
        form.resetFields();
        getCustomerProjects()
        setLoading(false)
      })
      .catch(error => {
        console.log(false);
        message.error("Error In creating Project", error)
        setLoading(false)
      });
  }

  const fetchSelectedUserDetails = (userId) => {
    if (userId) {
      let tempUser = usersList.filter(u => u.id === userId)
      if (tempUser.length > 0) {
        form.setFieldsValue({
          empname: tempUser[0].name,
          email: tempUser[0].email,
          active: tempUser[0].active
        });
      }
    } else {
      form.setFieldsValue({active: 1})
    }
    setIsModalOpen({ open: true, user: userId })
  }

  useEffect(() => {
    getCustomerProjects()
  }, [])
  return (
    <Auxiliary>
      <Row gutter={24}>
        <Col lg={20} md={20} sm={20} xs={20}  >
          <div className="gx-form-row0" >
          </div>
        </Col>
        <Col lg={4} md={4} sm={4} xs={4} className="text-end">
          <Button type="text" className="addBtn" onClick={() => { fetchSelectedUserDetails(null) }}>
            <i className="fa fa-plus" aria-hidden="true"></i>New Customer
          </Button>
        </Col>
      </Row>

      <Table className="gx-table-responsive" loading={loading} columns={tasksListCols} dataSource={usersList} size="small"
        bordered={false} key={'id'} />

      <Modal closable={false} centered keyboard={false} maskClosable={false} title={isModalOpen.user ? "Update Customer" : "New Customer"}
        visible={isModalOpen.open} okText={isModalOpen.user ? "Update Customer" : "New Customer"} onOk={() => { form.submit(); }}
        onCancel={() => { form.resetFields(); setIsModalOpen({ open: false, user: null }); }}
        wrapClassName={`${"modal-ticket-create"}`}>
        <Row gutter={24} >
          <Form
            form={form}
            name="taskForm"
            onFinish={onTaskFormFinish}
            layout="inline"
            align="center"
          >
            <Row gutter={24}>
              <Col lg={24} md={24} sm={24} xs={24} >
                <div className="gx-form-row0">
                  <h6 align="left">Name</h6>
                  <Form.Item
                    name="empname"
                    rules={[
                      {
                        required: true,
                        message: 'Enter the name',
                      },
                    ]}
                  >
                    <Input placeholder="Customer Name"></Input>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24} >
                <div className="gx-form-row0">
                  <h6 align="left">Email</h6>
                  <Form.Item
                    name="email"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          let regex = /^[a-z0-9]+@[a-z-]+\.[a-z]{2,3}$/;
                          let result = regex.test(value);
                          if (!result) {
                            return Promise.reject("Please enter the valid email address");
                          }
                          return Promise.resolve();
                        },
                      })
                    ]}
                  >
                    <Input placeholder="Email"></Input>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24} style={{ display: isModalOpen.user ? 'none' : 'block' }}>
                <div className="gx-form-row0">
                  <h6 align="left">Project</h6>
                  <Form.Item
                    name="project"
                    rules={[
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value && !isModalOpen.user) {
                            return Promise.reject("Please select the project");
                          }
                          return Promise.resolve();
                        },
                      })
                    ]}
                  >
                    <Select
                      showSearch
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Select a Project"
                      optionFilterProp="children"
                      filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}>
                      {projects.length ? projects.map((project, index) => (
                        <Option key={index} value={project.id}>{project.name}</Option>
                      )) : null}
                    </Select>
                  </Form.Item>
                </div>
              </Col>

              <Col lg={8} md={8} sm={12} xs={24} >
                <div className="gx-form-row0">
                  <h6 align="left">Is Active?</h6>
                  <Form.Item name="active" >
                    <RadioGroup onChange={(e) => onChangeIsActive(e)} value={isActive}>
                      <Radio value={0}>No</Radio>
                      <Radio value={1}>Yes</Radio>
                    </RadioGroup>
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>
      </Modal>
    </Auxiliary>
  );
};
export default ManageCustomer;