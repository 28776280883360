import React, { useEffect, useState } from "react";
import { Col, Row, Table, message, Button, Form, Input, Modal, Select, DatePicker } from "antd";
import axios from 'axios';
import Auxiliary from "../../../util/Auxiliary";
import { API_URL } from '../../../constants/APIConfig';
import IconWithTextCard from "../../../components/Metrics/IconWithTextCard";
import moment from 'moment';
import 'moment/min/locales';

const { TextArea } = Input;
const { RangePicker } = DatePicker;

const CustomerHome = () => {
  const [taskForm] = Form.useForm()
  const userDetails = JSON.parse(sessionStorage.getItem('user_details'))
  const token = sessionStorage.getItem('token')
  const [loading, setLoading] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [userTaskList, setUserTaskList] = useState([])
  const [filterTaskList, setFilterTaskList] = useState([])
  const [textAreaCount, setTextAreaCount] = React.useState(0);
  const [description, setDescription] = useState("");
  const [projects, setProjects] = useState([])
  const [projectId, setProjectId] = useState({})
  const [taskType, setTaskType] = useState('Incident')
  const [taskStats, setTaskStats] = useState({
    NEW: 0,
    'WAITING FOR INPUTS': 0,
    'IN PROGRESS': 0,
    RESOLVED: 0,
    CLOSED: 0,
    TOTAL: 0
  });
  const [selectedTaskCard, setSelectedTaskCard] = useState('Tasks')
  const [dates, setDates] = useState({ s: moment().subtract(4, 'weeks'), e: moment() })

  const tasksListCols = [
    {
      title: 'Id', dataIndex: 'id', width: 180,
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Name', dataIndex: 'name', width: 200,
      render: (text, record) => {
        return (
          <div>
            <span> {text} </span>
          </div>
        );
      },
    },
    {
      title: 'Description', dataIndex: 'description', width: 300,
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },
    {
      title: 'Assigned User', dataIndex: 'userName', width: 150,
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },
    {
      title: 'Task Type', dataIndex: 'status', sorter: (a, b) => String(a.status).localeCompare(String(b.status)),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },
    {
      title: 'Current Status', dataIndex: 'status', sorter: (a, b) => String(a.status).localeCompare(String(b.status)),
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div>
            <span> {text}</span>
          </div>
        );
      },
    },
    {
      title: 'Start Date', dataIndex: 'startDateDt', width: 130,
      render: (text, record) => {
        return (
          <div>
            <span> {record.startDateDt ? moment(record.startDateDt).format('ll') : null} </span>
          </div>
        );
      },
    },
    {
      title: 'Due Date', dataIndex: 'dueDateDt', width: 130,
      render: (text, record) => {
        return (
          <div>
            <span> {record.dueDateDt ? moment(record.dueDateDt).format('ll') : null} </span>
          </div>
        );
      },
    },
    {
      title: 'Created Date', dataIndex: 'createdAt', width: 160,
      sorter: (a, b) => {
        return moment(a.createdAt).isAfter(moment(b.createdAt))
      },
      sortDirections: ['descend', 'ascend'],
      render: (text, record) => {
        return (
          <div>
            <span> {record.createdAt ? moment(record.createdAt).format('lll') : null} </span>
          </div>
        );
      },
    },
  ]

  const openTaskForm = () => {
    if (projectId.id) {
      setIsModalOpen(true)
    } else {
      message.error('Please select the project')
    }
  }

  const onProjectChange = value => {
    projects.forEach(p => {
      if (p.id === value) {
        setProjectId({ id: value, name: p.name });
        getUserTasksList(value)
      }
    })
  }

  const onTypeChange = value => {
    setTaskType(value)
  }

  const createUserTask = (data) => {
    setLoading(true)
    if (taskType === 'CR') {
      data.isChangeRequest = true
    }
    axios.post(API_URL + `/api/customer/createTask`, data,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (response.status === 200) {
          getUserTasksList(projectId.id)
          message.success("Task created successfully");
          taskForm.resetFields()
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        message.error("Unexpected error occurred.");
        taskForm.resetFields()
      });
  }

  const handleDescription = (e) => {
    setDescription(e.target.value);
    setTextAreaCount(e.target.value.length);
  }

  const onTaskFormFinish = () => {
    let values = taskForm.getFieldsValue()
    values.projectId = projectId.id
    values.clientId = userDetails.id
    setIsModalOpen(false)
    createUserTask(values)
  }

  const getProjectList = () => {
    setLoading(true)
    axios.get(API_URL + `/api/customer/${userDetails.id}/projectlist?t=${new Date().getTime()}`,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        if (Object.keys(response.data.data).length > 0) {
          setProjects(response.data.data);
        }
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error);
      });
  }

  const getUserTasksList = (projectId) => {
    setLoading(true)
    let url = `/api/customer/${userDetails.id}/${projectId}/taskslist?t=${new Date().getTime()}`
    if (dates.s && dates.e) {
      url = url + `&sDate=${dates.s.format('DD-MM-YYYY')}&eDate=${dates.e.format('DD-MM-YYYY')}`
    }
    axios.get(API_URL + url,
      {
        headers: {
          'x-access-token': `${token}`,
          'Access-Control-Allow-Origin': '*'
        }
      })
      .then(response => {
        let newCount = 0
        let wipCount = 0
        let inpCount = 0
        let resCount = 0
        let cloCount = 0

        if (response.data && response.data.data) {
          response.data.data.forEach((d, i) => { d['key'] = i })
          setUserTaskList(response.data.data)
          response.data.data.forEach(d => {
            if(d.status === 'New') {
              newCount++
            } else if(d.statu === 'In Progress') {
              inpCount++
            } else if(d.statu === 'Waiting For Inputs') {
              wipCount++
            } else if(d.statu === 'Resolved') {
              resCount++
            } else if(d.statu === 'Closed') {
              cloCount++
            }
          })
        } else {
          setUserTaskList([])
        }

        setTaskStats({
          NEW: newCount,
          'WAITING FOR INPUTS': wipCount,
          'IN PROGRESS': inpCount,
          RESOLVED: resCount,
          CLOSED: cloCount,
          TOTAL: newCount + wipCount + inpCount + resCount + cloCount
        })
        setLoading(false)
      })
      .catch(error => {
        setLoading(false)
        console.log(error);
      });
  }

  const getTaskDetails = () => {
    if (dates.s && dates.e && projectId.id) {
      getUserTasksList(projectId.id)
    } else {
      message.error('Please select the start and end dates or selet the Project')
    }
  }

  const onTaskCardSelected = (v) => {
    setSelectedTaskCard(v)
  }

  const filterTaskTypeData = () => {
    let dList = []
    if(selectedTaskCard === 'Tasks') {
      dList = userTaskList
    } else {
      userTaskList.forEach(d => {
        if(d.status === selectedTaskCard) {
          dList.push(d)
        }
      })
    }
    
    setFilterTaskList(dList)
  }

  const getDateRange = (dates, dateStrings) => {
    setDates({ s: dates[0], e: dates[1] })
  }

  useEffect(() => {
    filterTaskTypeData()
  }, [selectedTaskCard, userTaskList])

  useEffect(() => {
    getProjectList()
  }, [])

  return (
    <Auxiliary>
      <Row gutter={24}>
        <Col lg={8} md={8} sm={12} xs={24}  >
          <div className="gx-form-row0" >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select the Project"
              optionFilterProp="children"
              onChange={onProjectChange}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                projects.length ? projects.map((pm, index) => (
                  { value: pm.id, label: pm.name }
                )) : []
              }
            >
            </Select>
          </div>
        </Col>
        <Col lg={6} md={6} sm={12} xs={24}>
          <div className="gx-form-row0" >
            <Select
              showSearch
              style={{ width: '100%' }}
              placeholder="Select the Type"
              optionFilterProp="children"
              onChange={onTypeChange}
              defaultValue={'Incident'}
              filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              options={
                [{ value: 1, label: 'Incident' }, { value: 2, label: 'Change Request' }]
              }
            >
            </Select>
          </div>
        </Col>
        <Col lg={2} md={2} sm={12} xs={24}>
          <Button type="text" className="addBtn" onClick={openTaskForm}>
            <i className="fa fa-plus" aria-hidden="true"></i>Create
          </Button>
        </Col>
        <Col lg={6} md={6} sm={12} xs={24} >
          <div className="gx-form-row0">
            <RangePicker className="gx-mb-3 gx-w-100" format="DD-MM-YYYY" onChange={getDateRange}
              defaultValue={[dates.s, dates.e]} allowClear={false} allowEmpty={false} />
          </div>
        </Col>
        <Col lg={2} md={2} sm={12} xs={24}>
          <Button type="primary" className="addBtn" onClick={getTaskDetails}>Fetch</Button>
        </Col>
      </Row>

      <Row gutter={24}>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Tasks') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="orders" iconColor="geekblue" title={taskStats.TOTAL} subTitle="All Tasks" tasksCard={selectedTaskCard === 'Tasks' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('New') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="revenue-new" iconColor="geekblue" title={taskStats.NEW} subTitle="New" tasksCard={selectedTaskCard === 'New' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('In Progress') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="queries" iconColor="geekblue" title={taskStats['IN PROGRESS']} subTitle="InProgress" tasksCard={selectedTaskCard === 'In Progress' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Waiting For Inputs') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="visits" iconColor="geekblue" title={taskStats['WAITING FOR INPUTS']} subTitle="Waiting" tasksCard={selectedTaskCard === 'Waiting For Inputs' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Resolved') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="check-circle-o" iconColor="primary" title={taskStats.RESOLVED} subTitle="Resolved" tasksCard={selectedTaskCard === 'Resolved' ? 'tasksCard' : ''} />
        </Col>
        <Col xl={4} lg={4} md={12} sm={12} xs={12} className="gx-col-full scale-x" onClick={() => { onTaskCardSelected('Closed') }} style={{ cursor: "pointer" }}>
          <IconWithTextCard icon="close-circle" iconColor="primary" title={taskStats.CLOSED} subTitle="Closed" tasksCard={selectedTaskCard === 'Closed' ? 'tasksCard' : ''} />
        </Col>
      </Row>

      <Table className="gx-table-responsive" loading={loading} columns={tasksListCols} dataSource={filterTaskList} size="small"
        bordered={false} key={'id'} />

      <Modal closable={false} centered keyboard={false} maskClosable={false} title={"New Task"}
        visible={isModalOpen} okText={"New Task"} onOk={() => { taskForm.submit(); }}
        onCancel={() => { taskForm.resetFields(); setIsModalOpen(false); }}
        wrapClassName={`${"modal-ticket-create"}`}>
        <Row gutter={24} >
          <Form
            form={taskForm}
            name="taskForm"
            onFinish={onTaskFormFinish}
            layout="inline"
            align="center"
          >
            <Row gutter={24}>
              <Col lg={24} md={24} sm={24} xs={24} >
                <div className="gx-form-row0">
                  <b>{projectId.name}</b>
                </div>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24} >
                <div className="gx-form-row0">
                  <h6 align="left">Name</h6>
                  <Form.Item
                    name="name"
                    rules={[
                      {
                        required: true,
                        message: 'Enter the name',
                      },
                    ]}
                  >
                    <Input placeholder="Task Name"></Input>
                  </Form.Item>
                </div>
              </Col>
              <Col lg={24} md={24} sm={24} xs={24} >
                <div className="gx-form-row0">
                  <h6 align="left">Description</h6>
                  <Form.Item
                    name="description"
                    rules={[
                      {
                        required: true,
                        message: 'Enter the description',
                      },
                    ]}
                  >
                    <TextArea placeholder="Task description" onChange={e => handleDescription(e)} rows={4} value={description} maxLength={255} />

                  </Form.Item>
                  <p style={{ textAlign: 'right', marginTop: '10px', marginBottom: '0px' }}> {`${textAreaCount} / 255`} </p>
                </div>
              </Col>
            </Row>
          </Form>
        </Row>

      </Modal>

    </Auxiliary>
  );
};
export default CustomerHome;